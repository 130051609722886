import axios from "axios";
import { getItem, setItem } from "../services/localStorageService";
import { authUser, guestUser, authUserCredentials } from "../constants";
import { apiEndPoints } from "./index";
import apiUrls from "../hooks/getApiBaseUrl";
import { differenceInMinutes } from "date-fns";
import jwt_decode from "jwt-decode";
import { aws4Interceptor } from "aws4-axios";
import { aws_region } from "config";

const createAPI = () => {
  const apiHeader = {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  };

  const api = axios.create({
    // baseURL: 'https://api.sbchardening.com',
    // baseURL: 'http://localhost:3000/dev/',
    // baseURL: 'https://f8t0lfnl2a.execute-api.us-east-2.amazonaws.com/development',
    baseURL: apiUrls.baseURL,
    // baseURL: "https://dq8emp6pyj.execute-api.us-east-2.amazonaws.com/development/",
    //baseURL: "https://rcxy1cwy6i.execute-api.us-east-2.amazonaws.com/development/",
    apiHeader,
  });

  api.interceptors.request.use(async (config) => {
    const getCredentialsData = getItem(authUserCredentials);
    let interceptor;
    if (getCredentialsData) {
      interceptor = aws4Interceptor(
        {
          region: aws_region,
          service: "execute-api",
        },
        {
          accessKeyId: getCredentialsData.accessKeyId,
          secretAccessKey: getCredentialsData.secretAccessKey,
          sessionToken: getCredentialsData.sessionToken,
        }
      );

      const { headers } = await interceptor(config);
      config.headers = headers;
    }

    return config;
  });

  api.interceptors.request.use(async (config) => {
    let bearerToken;
    const tokens = getItem(authUser);
    const guestUserData = getItem(guestUser);

    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const paramToken = parameters.get("token");

    const idToken = tokens ? tokens.idToken : "";

    const token =
      guestUserData && guestUserData.role === "guest"
        ? guestUserData.accessToken
        : idToken || paramToken;
    if (bearerToken !== "GUEST") {
      config.headers["authtoken"] = token;
      if (token) {
        const decoded_values = jwt_decode(token);
        if (decoded_values) {
          const minutesToExpire = differenceInMinutes(
            new Date(decoded_values.exp * 1000),
            new Date()
          );

          if (
            minutesToExpire < 14 &&
            !config.url.includes(apiEndPoints.getRefreshToken)
          ) {
            try {
              const result = await refreshToken();
              if (result.status === 200) {
                const { data } = result.data;
                setItem(authUser, {
                  accessToken: data.token.accessToken,
                  idToken: data.token.idToken,
                  refreshToken: data.token.refreshToken,
                });

                setItem(authUserCredentials, data.credentials);

                config.headers["authtoken"] = data.token.idToken;
              } else {
                // window.location = "session/signin";
                // window.localStorage.clear();
              }
            } catch (error) {
              // window.location = "session/signin";
              // window.localStorage.clear();
            }
          }
        }
      }
    }
    return config;
  });
  const refreshToken = () => {
    const userEmail = getItem("userInfo") && getItem("userInfo").email;
    const tokens = getItem(authUser) || null;
    const refreshToken = tokens && tokens.refreshToken;
    const payload = {
      email: userEmail,
      refreshToken: refreshToken,
      customCognitoClientId: getItem("cognitoClientId") || undefined,
    };

    return api.post(apiEndPoints.getRefreshToken, payload);
  };

  api.interceptors.response.use(
    async (response) => {
      const serverErrorMsg = ["Unauthorized", "Missing Authentication Token"];
      const expiredToken =
        response.data.message === "The incoming token has expired";
      const serverError = !(
        serverErrorMsg.findIndex((d) => d === response.data.message) < 0
      );
      if (expiredToken) {
        try {
          const result = await refreshToken();
          if (result.status === 200) {
            const { data } = result.data;
            setItem(authUser, {
              accessToken: data.token.accessToken,
              idToken: data.token.idToken,
              refreshToken: data.token.refreshToken,
            });
          } else {
            // window.location = "session/signin";
            // window.localStorage.clear();
          }
        } catch (error) {
          // window.location = "session/signin";
          // window.localStorage.clear();
        }
        return 0;
      } else if (serverError) {
        // window.location = "session/signin";
        // window.localStorage.clear();
      }
      const { data, status, statusText } = response;
      return { data, status, statusText };
    },
    (error) => {
      throw error;
    }
  );
  return api;
};

export default createAPI();
